@use "../../globals/globals.scss";

.img-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 8rem;
  margin-bottom: 5rem;
  width: 100%;

  @media screen and (max-width: 900px) {
    justify-content: center;
  }
}

img {
  width: 70%;
  height: auto;
}

.btn-wrap-home {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media screen and (max-width: 900px) {
    justify-content: center;
    margin-bottom: 30rem;
  }

  .btn {
    border: none;
    background: globals.$btn-color;
    margin-top: -5rem;
    border-radius: 8px;
    width: 15rem;
    padding: 0.5rem 2rem;

    &:hover {
      background: globals.$btn-hover-color;
    }
  }
}

.home-container {
  height: 100vh;
  overflow: hidden;
}
