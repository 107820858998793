@use "../../globals/globals.scss";

.mainrow {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  .middle {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;

    a {
      text-decoration: none;
      font-family: "book-antiqua";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
      line-height: 2.5rem;
      color: globals.$para-color;
    }

    .pdf-icon {
      color: red;
      font-size: 1rem;
      font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);

      @media screen and (max-width: 777px) {
        display: none;
      }
    }
  }
}
