@use "../../globals/globals.scss";

.footer-wrapper {
  background-color: #1c1d1e;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 5rem;
  width: 100%;
  margin-top: 5rem;
  height: auto;

  @media (max-width: 1150px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-link {
    font-family: "book-antiqua";
    text-decoration: none;
    color: #f8fafc !important;
    font-weight: 600;
    font-size: 1rem;
    font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
  }

  .footer-row-1 {
    margin: 5rem 0rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .footer-row-2 {
    margin: 5rem 0rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .footer-row-3 {
    display: flex;
    flex-direction: column-reverse;
    color: aliceblue;

    .logo {
      margin-top: 10rem;
      height: 15rem;

      @media (max-width: 1150px) {
        margin-top: 1rem;
        height: 10rem;
        width: auto;
      }
    }
  }
}
