@use "../../globals/globals.scss";

.about-container {
  .about-intro-section {
    margin: 10rem 0rem;

    .about-title {
      font-family: "Book-Antiqua";
      font-style: normal;
      font-weight: 500;
      color: globals.$title-color;
      font-size: 5rem;
      font-size: clamp(1rem, 2rem + 2vw, 5rem);
      margin: 0;
    }

    .about-text {
      font-family: "Book-Antiqua";
      font-style: italic;
      font-weight: 400;
      line-height: 36px;
      color: globals.$para-color;
      font-size: 1rem;
      font-size: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
      margin-top: 3rem;
      padding-right: 4rem;

      ul {
        padding-left: 1rem;
        margin-top: 3rem;
      }
    }

    .about-contact-btn {
      background: globals.$btn-color;
      border-radius: 4px;
      border: none;
      outline: none;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      margin-top: 4rem;

      &:hover {
        background: globals.$btn-hover-color;
      }
    }

    .about-img-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-bottom: auto;

      .gelo {
        width: 100%;

        @media screen and (max-width: 991px) {
          margin-top: 5rem;
        }
      }

      @media screen and (max-width: 768px) {
        margin-top: 5rem;
      }
    }
  }

  .about-title-row {
    margin: 5rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    text-align: center;

    .about-section-title {
      font-family: "Book-Antiqua";
      font-weight: 700;
      font-size: 1.25rem;
      font-size: clamp(1.25rem, 1rem + 1.25vw, 2.5rem);
    }
  }

  .marginizer {
    margin-bottom: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      margin-bottom: 0rem;
    }
  }

  .principles-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    column-gap: 50px;

    .number-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .principles-text {
      font-family: "Book-Antiqua";
      font-style: italic;
      font-weight: 500;
      font-size: 0.625rem;
      font-size: clamp(0.625rem, 0.5rem + 0.625vw, 1.25rem);
      line-height: 40px;
      color: globals.$para-color;
    }

    .numberCircle {
      box-sizing: border-box;
      width: clamp(2rem, 4.95rem + 0.25vw, 6.25rem);
      height: clamp(2rem, 4.95rem + 0.25vw, 6.25rem);
      border-radius: 100%;
      border: 1px solid #bdbdbd;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: black;
      font-family: "Book-Antiqua";
      font-style: normal;
      font-weight: 700;
      font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
      line-height: clamp(5rem, 4.95rem + 0.25vw, 6.25rem);
    }
  }

  .s1 {
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
  }

  .col1 {
    margin-bottom: 1rem;
  }
}
