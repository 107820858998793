@use "../../globals/globals.scss";

.contact-container {
  margin-top: 10rem;

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-left: 20rem;

    .header {
      margin: 0;
      padding: 0;
      color: globals.$title-color;
      font-size: clamp(1rem, 2rem + 2vw, 5rem);
      font-family: "book-antiqua";
      font-style: bold;
      font-weight: 500;
      margin-bottom: 5rem;
    }

    .txt {
      font-family: "book-antiqua";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
    }

    img {
      width: 60%;
    }

    @media screen and (max-width: 1350px) {
      padding-left: unset;
      flex-direction: column;
      row-gap: 5rem;
      justify-content: center;
      align-items: center;

      .img {
        width: 100%;
      }
    }

    .k-detail {
      display: flex;
      flex-direction: column;
      row-gap: 3rem;
    }

    .k-detail2 {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
    }
  }
}
