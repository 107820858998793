@use "../../globals/globals.scss";

.zemljoknjizno-container {
  display: flex;
  flex-direction: column;
  row-gap: 5rem;

  .zemljoknjizno-title {
    font-family: "book-antiqua";
    font-weight: 500;
    font-size: 5rem;
    font-size: clamp(1rem, 2rem + 2vw, 5rem);
    font-style: normal;
    color: globals.$title-color;
    margin-top: 8rem;
  }

  .zemljoknjizno-contact-btn {
    background: globals.$btn-color;
    border-radius: 4px;
    border: none;
    outline: none;
    font-family: "book-antiqua";
    font-style: normal;
    font-weight: 500;
    margin-top: 2rem;

    &:hover {
      background: globals.$btn-hover-color;
    }
  }

  .zemljoknjizno-para {
    font-family: "book-antiqua";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    font-size: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
    line-height: 2.5rem;
    color: globals.$para-color;
    text-align: justify;
  }

  .zemljoknjizno-img-container {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
