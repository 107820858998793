@use "./globals/globals.scss";

@font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("book-antiqua"), url("./fonts/book-antiqua.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  background: #f5f5f5;
}

.logo {
  height: 2.893rem;
  width: auto;
}

.dropdown-menu {
  border: none;
  box-shadow: 0px 4px 24px rgba(94, 119, 141, 0.15);
}

.bg-light {
  background: globals.$main-white !important;
  box-shadow: 0px 4px 24px rgba(94, 119, 141, 0.15);
}

.nav-link {
  font-size: 1.125rem;
  font-size: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem);
  margin-right: 5em;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
}

.active {
  border-bottom: 0.4rem solid globals.$btn-color;
  color: #1c1d1e !important;
}

// Navbar style
